// Jeder Block muss zweimal hinzugefügt werden.
// 1. Block importieren:
import BlockNotFound from "gatsby-theme-yum-components/src/components/blocks/BlockNotFound"
import HeroBlock from "gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import TextBlock from "gatsby-theme-yum-components/src/components/blocks/TextBlock"
import TextImageBlock from "gatsby-theme-yum-components/src/components/blocks/TextImageBlock"
import ImageBlock from "gatsby-theme-yum-components/src/components/blocks/ImageBlock"
import QuoteBlock from "gatsby-theme-yum-components/src/components/blocks/QuoteBlock"
import VideoBlock from "gatsby-theme-yum-components/src/components/blocks/VideoBlock"
import TextVideoBlock from "gatsby-theme-yum-components/src/components/blocks/TextVideoBlock"
import FeaturesBlock from "gatsby-theme-yum-components/src/components/blocks/FeaturesBlock"
import TeamBlock from "gatsby-theme-yum-components/src/components/blocks/TeamBlock"
import CallToActionBlock from "gatsby-theme-yum-components/src/components/blocks/CallToActionBlock"
import StatsBlock from "gatsby-theme-yum-components/src/components/blocks/StatsBlock"
import EmbedBlock from "gatsby-theme-yum-components/src/components/blocks/EmbedBlock"
import GalleryBlock from "gatsby-theme-yum-components/src/components/blocks/GalleryBlock"
import FolderBlock from "gatsby-theme-yum-components/src/components/blocks/FolderBlock"
import DownloadsBlock from "gatsby-theme-yum-components/src/components/blocks/DownloadsBlock"
import LogoGridBlock from "gatsby-theme-yum-components/src/components/blocks/LogoGridBlock"
import TableBlock from "gatsby-theme-yum-components/src/components/blocks/TableBlock"
import MasonryGridBlock from "../../../components/blocks/MasonryGridBlock"
import IllustrationVideoMapBlock from "../../../components/blocks/IllustrationVideoMapBlock"

// 2. CMS-Block auf React-Block mappen:
const BlocksList = {
  hero_block: HeroBlock,
  text_block: TextBlock,
  text_image_block: TextImageBlock,
  image_block: ImageBlock,
  quote_block: QuoteBlock,
  video_block: VideoBlock,
  text_video_block: TextVideoBlock,
  features_block: FeaturesBlock,
  team_block: TeamBlock,
  cta_block: CallToActionBlock,
  stats_block: StatsBlock,
  masonry_grid_block: MasonryGridBlock,
  embed_block: EmbedBlock,
  gallery_block: GalleryBlock,
  folder_block: FolderBlock,
  downloads_block: DownloadsBlock,
  logo_grid_block: LogoGridBlock,
  table_block: TableBlock,
  illustration_video_map_block: IllustrationVideoMapBlock,
}

// Diese Logik kann unverändert bleiben
const Blocks = (type) => {
  if (typeof BlocksList[type] === "undefined") {
    return BlockNotFound
  }
  return BlocksList[type]
}

export default Blocks
