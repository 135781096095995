import React, { useState, useRef, useEffect } from "react"
import Card from "../../components/atoms/card"

import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const useMasonryReveal = () => {
  const refMasonryReveals = useRef([])
  refMasonryReveals.current = []

  const refMasonryRevealsCollection = (el) => {
    if (el && !refMasonryReveals.current.includes(el)) {
      refMasonryReveals.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refMasonryReveals.current, {
      autoAlpha: 0,
      duration: 1.5,
      stagger: 0.15,
      ease: "power1.out",
      scrollTrigger: {
        trigger: refMasonryReveals.current[0],
        toggleActions: "play none none reverse",
        start: "start 75%",
        end: "bottom 25%",
      },
    })
  }, [])
  return [refMasonryRevealsCollection]
}

const MasonryGridBlock = ({ block }) => {
  const [refMasonryRevealsCollection] = useMasonryReveal()

  const refShowMore = useRef()
  const [showMoreState, setShowMoreState] = useState(true)

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setShowMoreState(false)
    }, 1000)
    setTimeout(() => {
      ScrollTrigger.refresh(true)
    }, 1500)
  }, [])

  const toggleShowMore = () => {
    if (showMoreState === false) {
      setShowMoreState(true)
    } else {
      setShowMoreState(false)
    }
    setTimeout(() => {
      ScrollTrigger.refresh(true)
    }, 500)
  }

  return (
    <BlockWrapper block={block} className="" showHeadline={true}>
      <div className="flex flex-row flex-wrap mt-8 -mx-grid">
        {block.cards?.map(
          (item, index) =>
            index < 9 && (
              <div
                ref={refMasonryRevealsCollection}
                className={`w-full sm:w-1/2 md:w-1/3 px-grid`}
                key={index}
              >
                <Card item={item} />
              </div>
            )
        )}
      </div>
      <div
        className="overflow-hidden transition-all duration-500"
        ref={refShowMore}
        style={{
          height: `${
            showMoreState === true
              ? `${refShowMore.current?.scrollHeight}px`
              : "0px"
          }`,
        }}
      >
        <div className="flex flex-row flex-wrap mt-8 -mx-grid">
          {block.cards?.map(
            (item, index) =>
              index >= 9 && (
                <div className={`w-full sm:w-1/2 md:w-1/3 px-grid`} key={index}>
                  <Card item={item} />
                </div>
              )
          )}
        </div>
      </div>
      {block.cards?.length > 8 && (
        <button
          className="w-full btn btn-secondary"
          onClick={() => toggleShowMore()}
        >
          <FontAwesomeIcon
            icon={showMoreState === false ? faCaretDown : faCaretUp}
            size="1x"
            className="mr-2 text-xl leading-none"
          />
          {showMoreState === false ? "Mehr anzeigen" : "Weniger anzeigen"}
        </button>
      )}
    </BlockWrapper>
  )
}

export default MasonryGridBlock
