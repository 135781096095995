import React from "react"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ButtonGroup from "gatsby-theme-yum-components/src/components/atoms/ButtonGroup"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "gatsby-theme-yum-components/src/components/reveals/TextReveal"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"

const CallToActionBlock = ({ block }) => {
  let blockWidth =
    block.block_width?.length > 0 ? block.block_width : "container"
  return (
    <BlockWrapper block={block} blockWidth={blockWidth} showHeadline="true">
      <div className="flex flex-wrap mt-8 flew-row -mx-grid">
        <div
          className={`w-full mb-4 ${
            blockWidth === "container" ? "lg:w-1/2 lg:mb-0" : ""
          } px-grid`}
        >
          <TextReveal>
            <Richtext text={block.text} />
          </TextReveal>
          {block.image?.filename?.length > 0 && (
            <ButtonGroup className="mt-4" buttons={block.buttons} />
          )}
        </div>
        <div
          className={`w-full ${
            blockWidth === "container" ? "lg:w-1/2" : ""
          } px-grid`}
        >
          {block.image?.filename?.length > 0 ? (
            <Image image={block.image} aspectRatio="16by9" />
          ) : (
            <ButtonGroup className="lg:mt-1" buttons={block.buttons} />
          )}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default CallToActionBlock
