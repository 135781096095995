import React from "react"

const Video = (props) => {
  const { video_url, ratio, _uid, className } = props
  let video
  let video_code
  if (video_url.includes("watch?")) {
    video = video_url.split("?v=")
    video_code = video[1]
  }
  if (video_url.includes("/embed/")) {
    video = video_url.split("/embed/")
    video_code = video[1]
  }
  if (video_url.includes(".be/")) {
    video = video_url.split(".be/")
    video_code = video[1]
  }
  return (
    <div className={`embed embed-${ratio}`}>
      <iframe
        className="embed-item"
        title={"iframe-" + _uid}
        width="1920"
        height="1080"
        src={"https://www.youtube-nocookie.com/embed/" + video_code}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

export default Video
