// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   embed_code -> textarea / 
//   ratio -> option / Seitenverhältnis
//   block_padding -> option / Abstände
// end of automatic

import React from "react"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const EmbedBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} blockPadding={block.block_padding}>
      {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white">
            <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
            <b>Embed-Block:</b> Eingebetteten Inhalte, welche Scripte verwenden,
            können unter Umständen in der <i>Vorschau</i> nicht korrekt
            angezeigt werden.
          </p>
        </div>
      )}
      <div
        className={`${
          block.ratio !== "none" ? "embed embed-" + block.ratio : ""
        }`}
        dangerouslySetInnerHTML={{ __html: block.embed_code }}
      ></div>
    </BlockWrapper>
  )
}

export default EmbedBlock
