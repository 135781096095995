// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   headline -> text / Überschrift
//   headline_level -> option
//   logo_items -> bloks
// end of automatic

import React from "react"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Icon from "gatsby-theme-yum-components/src/components/atoms/Icon"

const LogoGridBlock = ({ block }) => {
  const sizes = {
    small: "logo-sm",
    normal: "logo-md",
    large: "logo-lg",
  }
  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className="flex flex-row flex-wrap pt-8 -mx-grid">
        {block.logo_items?.length > 0 &&
          block.logo_items.map((item, index) => (
            <div
              className="relative w-1/2 md:w-1/3 lg:w-1/4 px-grid"
              key={index}
            >
              <div className="relative flex items-center justify-center w-full h-full py-4 text-center px-grid">
                <Link className="block w-full h-auto group" link={item.link}>
                  <Image
                    image={item.image}
                    className={`block w-full h-auto ${sizes[item.logo_size]}`}
                  />

                  {item.link.url?.length > 0 && (
                    <span className="absolute right-4 bottom-4">
                      <Icon
                        icon={{ type: "fas", icon: "fa-external-link-alt" }}
                        size="1x"
                        className="group-hover:text-primary"
                      />
                    </span>
                  )}
                </Link>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default LogoGridBlock
