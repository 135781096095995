// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   view -> option / Ansicht
//   image_items -> bloks / Bilder
// end of automatic

import React from "react"
import SliderGalleryBlock from "./GalleryBlock/SliderGalleryBlock"
import ThumbnailGalleryBlock from "./GalleryBlock/ThumbnailGalleryBlock"

const GalleryBlock = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "slider"
  if (block.view === "slider") {
    return <SliderGalleryBlock block={block} />
  } else {
    return <ThumbnailGalleryBlock block={block} />
  }
}
export default GalleryBlock
