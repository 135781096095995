import React, { useEffect, useRef } from "react"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import ThemeLogo from "../../../components/modules/ThemeLogo"

import useParallaxScroll from "gatsby-theme-yum-components/src/hooks/useParallaxScroll"
import useMediaQuery from "gatsby-theme-yum-components/src/hooks/useMediaQuery"

gsap.registerPlugin(ScrollTrigger)

const useLogoScroll = () => {
  const logoRef = useRef(null)
  //const parentRef = useRef(null)

  useEffect(() => {
    gsap.from(logoRef.current, {
      autoAlpha: 0,
      x: 100,
      duration: 2,
      ease: "power3.inOut",
    })
  }, [])
  return { logoRef }
}
const useKenBurns = () => {
  const imageRef = useRef(null)

  useEffect(() => {
    gsap.to(imageRef.current, {
      scale: 1.2,
      duration: 15,
      ease: "power1.out",
    })
  }, [])
  return { imageRef }
}

const HeroBlock = ({ block }) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`)
  const { logoRef } = useLogoScroll()
  const { refParallaxCollection } = useParallaxScroll()

  const [themeLogo] = ThemeLogo(block.venue)

  const { imageRef } = useKenBurns()

  return (
    <BlockWrapper
      block={block}
      blockPadding=""
      blockWidth="screen"
      className="relative"
    >
      <div
        className="absolute top-0 right-0 z-50 w-1/2 h-auto px-8 pt-4 ml-auto opacity-100 lg:w-72"
        ref={logoRef}
      >
        {themeLogo && (
          <div className="relative w-full pb-24 ml-auto sm:w-48 lg:w-56 lg:pb-32">
            <div className="absolute top-0 right-0 w-auto h-full">
              <Image
                image={themeLogo}
                className={`object-contain object-right-top h-full w-full`}
              />
            </div>
          </div>
        )}
      </div>
      <div className="relative block overflow-hidden ">
        <div
          className="w-full h-auto overflow-hidden lg:h-screen-80"
          ref={imageRef}
        >
          <div
            className="w-full lg:h-full"
            data-depth="-1.5"
            ref={refParallaxCollection}
          >
            {block.image?.filename?.length > 0 && (
              <Image
                fixedSize={isMobile ? "960x540" : ""}
                image={block.image}
                className={`object-cover w-full h-auto lg:h-screen transform ${
                  block.flip_image && "-scale-1"
                }`}
              />
            )}
          </div>
        </div>
        <div className="absolute top-0 bottom-0 left-0 w-1/2 opacity-25 bg-gradient-to-r from-black lg:w-0 md:w-1/3 sm:w-2/5"></div>
        {block.image?.copyright?.length > 0 && (
          <div className="absolute bottom-0 right-0 px-1 bg-white bg-opacity-75">
            <small>© {block.image.copyright}</small>
          </div>
        )}
      </div>
    </BlockWrapper>
  )
}

export default HeroBlock
