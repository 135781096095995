// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   video_url -> text / Video-URL
//   caption -> textarea / Bildbeschreibung (optional)
// end of automatic

import React from "react"
import BlockWrapper from "../technical/BlockWrapper"

import VideoFigure from "../atoms/VideoFigure"

const VideoBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} className="fade-in-up animation-delay-1000">
      <VideoFigure
        video_url={block.video_url}
        ratio={block.ratio}
        _uid={block._uid}
        caption={block.caption}
      />
    </BlockWrapper>
  )
}

export default VideoBlock
