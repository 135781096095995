import React from "react"

import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import * as moment from "moment"

const Card = ({ item }) => {
  return (
    <article className="block h-full pb-8 ">
      <Link
        className="block h-full overflow-hidden shadow-lg group"
        link={"/" + item.url}
      >
        <div className="object-cover overflow-hidden">
          {item.image && item.image.filename && (
            <Image
              className="object-cover w-full duration-200 transform group-hover:scale-105"
              image={item.image}
              aspectRatio="16by9"
            />
          )}
        </div>
        <div className="p-4">
          <span className="block mb-2 leading-normal text-gray-600">
            {item.kicker}
          </span>
          <div className="flex flex-row justify-between lg:text-lg">
            <span
              className="inline-block font-bold lg:line-clamp-3 xl:line-clamp-2 font-display lg:w-3/4 group-hover:text-gray-800"
              style={{ hyphens: "auto" }}
            >
              {item.headline}
            </span>
            <span className="inline-block ml-4 text-gray-500 font-display">
              {item.moreDates && <span>ab </span>}
              {item.date?.datetime?.length > 0 && (
                <>{moment(item.date.datetime).format("DD.MM.")}</>
              )}
            </span>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Card
