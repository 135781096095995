import React from "react"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import ImageReveal from "gatsby-theme-yum-components/src/components/reveals/ImageReveal"

const Figure = ({
  className,
  image,
  caption,
  image_width = "container",
  aspectRatio,
}) => {
  return (
    <figure className={className}>
      <ImageReveal>
        <div className="relative overflow-hidden">
          <Image
            image={image}
            aspectRatio={aspectRatio}
            className="w-full h-auto"
          />
        </div>
      </ImageReveal>
      {((caption && caption.length > 0) ||
        image.title?.length > 0 ||
        image.copyright?.length > 0) && (
        <div className={`-mx-grid`}>
          <figcaption className="w-full max-w-screen-lg mx-auto mt-4 px-grid">
            {caption && caption.length > 0 ? (
              <span className="mr-4">{caption}</span>
            ) : image.title?.length > 0 ? (
              <span className="mr-4">{image.title}</span>
            ) : (
              ""
            )}
            {image.copyright?.length > 0 && <small>© {image.copyright}</small>}
          </figcaption>
        </div>
      )}
    </figure>
  )
}

export default Figure
