// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   folder_items -> bloks / Tabs/Akkordeon-Abschnitte
//   view -> option / Ansicht
// end of automatic

import React, { useState } from "react"
import AccordionFolderBlock from "./FolderBlock/AccordionFolderBlock"
import TabFolderBlock from "./FolderBlock/TabFolderBlock"

const FolderBlock = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "tabs"
  if (block.view === "tabs") {
    return <TabFolderBlock block={block} />
  } else {
    return <AccordionFolderBlock block={block} />
  }
}

export default FolderBlock
