import React, { useState } from "react"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"

import mapImage from "../../images/illustration-video-map.jpg"

import useModal from "gatsby-theme-yum-components/src/hooks/useModal"
import VideoFigure from "../../../../../themes/gatsby-theme-yum-components/src/components/atoms/VideoFigure"

const IllustrationVideoMapBlock = ({ block }) => {
  const [modalSingleVideo, setModalSingleVideo] = useState(null)
  const [modalProgramVideo, setModalProgramVideo] = useState(0)
  const { Modal, setShowModal } = useModal()

  const modalVideos = {
    bike: {
      video_url: block.bike_video_url,
      caption: block.bike_video_caption,
    },
    needle: {
      video_url: block.needle_video_url,
      caption: block.needle_video_caption,
    },
    saw: {
      video_url: block.saw_video_url,
      caption: block.saw_video_caption,
    },
    hello: {
      video_url: block.hello_video_url,
      caption: block.hello_video_caption,
    },
    bye: {
      video_url: block.bye_video_url,
      caption: block.bye_video_caption,
    },
    info: {
      video_url: block.info_video_url,
      caption: block.info_video_caption,
    },
    construction: {
      video_url: block.construction_video_url,
      caption: block.construction_video_caption,
    },
  }

  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className={block.headline?.length > 0 ? "mt-8" : ""}>
        {typeof window !== "undefined" && window.storyblok?.inEditor && (
          <div
            className="p-4 mb-8 text-white rounded"
            style={{ backgroundColor: "#09b3af" }}
          >
            <p>
              Sobald eine Video-URL für eine Link-Fläche gepflegt wurde, wird
              diese zur Orientierung (nur in der Storyblok-Vorschau) angezeigt.
            </p>
          </div>
        )}
        <div className="relative ">
          <Image image={mapImage} />
          {/* bike */}
          {modalVideos?.bike?.video_url?.length > 0 && (
            <button
              style={{
                height: "20%",
                width: "17.5%",
                left: "4.5%",
                top: "72%",
              }}
              className="absolute block 0"
              onClick={() => {
                setModalSingleVideo("bike")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* needle  */}
          {modalVideos?.needle?.video_url?.length > 0 && (
            <button
              style={{ height: "11%", width: "7%", left: "12%", top: "40%" }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("needle")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* saw */}
          {modalVideos?.saw?.video_url?.length > 0 && (
            <button
              style={{ height: "10%", width: "15%", left: "25%", top: "37%" }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("saw")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* hello */}
          {modalVideos?.hello?.video_url?.length > 0 && (
            <button
              style={{ height: "29%", width: "11%", left: "34.5%", top: "51%" }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("hello")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* program */}
          {block.illustration_video_map_program_items?.length > 0 && (
            <button
              style={{ height: "12.5%", width: "10%", left: "46%", top: "47%" }}
              className="absolute block"
              onClick={() => {
                setModalSingleVideo("program")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* bye */}
          {modalVideos?.bye?.video_url?.length > 0 && (
            <button
              style={{ height: "17%", width: "18%", left: "40%", top: "83%" }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("bye")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* info */}
          {modalVideos?.info?.video_url?.length > 0 && (
            <button
              style={{
                height: "28%",
                width: "10.5%",
                left: "59.5%",
                top: "72%",
              }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("info")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
          {/* construction */}
          {modalVideos?.construction?.video_url?.length > 0 && (
            <button
              style={{
                height: "21.5%",
                width: "16.5%",
                left: "74.5%",
                top: "52.5%",
              }}
              className="absolute block "
              onClick={() => {
                setModalSingleVideo("construction")
                setShowModal(true)
              }}
            >
              {typeof window !== "undefined" && window.storyblok?.inEditor && (
                <div className="flex items-center justify-center w-full h-full text-center bg-white bg-opacity-50"></div>
              )}
            </button>
          )}
        </div>
      </div>
      <Modal>
        {/* Simple video or playlist */}
        {modalSingleVideo !== null && modalSingleVideo !== "program" ? (
          <VideoFigure
            video_url={modalVideos[modalSingleVideo].video_url}
            caption={modalVideos[modalSingleVideo].caption}
          ></VideoFigure>
        ) : (
          <div>
            {modalProgramVideo !== null &&
              block.illustration_video_map_program_items[modalProgramVideo]
                ?.video_url?.length > 0 && (
                <VideoFigure
                  className="mb-8"
                  video_url={
                    block.illustration_video_map_program_items[
                      modalProgramVideo
                    ]?.video_url
                  }
                  caption={
                    block.illustration_video_map_program_items[
                      modalProgramVideo
                    ]?.caption
                  }
                ></VideoFigure>
              )}
            <div className="mb-4 text-xl font-bold">Unser Programm:</div>
            <div className="flex flex-row flex-wrap -mx-grid">
              {block.illustration_video_map_program_items?.map(
                (item, index) => (
                  <div className="w-1/2 pb-4 px-grid sm:w-1/4 xl:w-1/5">
                    <button
                      className={`block overflow-hidden shadow-lg ${
                        modalProgramVideo === index
                          ? " pointer-events-none"
                          : ""
                      }`}
                      onClick={() => {
                        setModalProgramVideo(index)
                      }}
                    >
                      <Image
                        className="transition-transform duration-200 transform hover:scale-105"
                        image={item.image}
                      />
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </Modal>
    </BlockWrapper>
  )
}

export default IllustrationVideoMapBlock
