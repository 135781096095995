import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Helmet } from "react-helmet"

const useModal = () => {
  const [showModal, setShowModal] = useState(false)

  let bodyClass = ""
  if (Helmet.peek()) {
    const helmetAttributes = Helmet.peek()
    bodyClass = helmetAttributes.bodyAttributes.class
  }

  const Modal = (props) =>
    showModal && (
      <>
        <Helmet
          bodyAttributes={{
            class: bodyClass + " overflow-hidden",
          }}
        />
        <div
          className="fixed inset-0 z-50 bg-black opacity-50"
          onClick={() => setShowModal(false)}
        ></div>
        <div className="fixed inset-0 z-50 pointer-events-none">
          <div
            className="pl-0 pr-0 mx-auto max-w-screen-2xl md:pr-8 md:pl-8 lg:pl-80 xl:pr-32"
            style={{
              paddingTop: "5vh",
              paddingBottom: "5vh",
            }}
          >
            <div
              className="relative p-4 overflow-y-auto bg-white shadow-lg pointer-events-auto md:p-8 "
              style={{
                maxHeight: "90vh",
              }}
            >
              <button
                className="absolute top-0 right-0 w-10 h-10 px-3 py-2 leading-none hover:text-primary"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  className="inline-block w-full h-full"
                />
              </button>
              {props.children}
            </div>
          </div>
        </div>
      </>
    )

  return { Modal, showModal, setShowModal }
}

export default useModal
