// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown / Text
//   image -> asset / Bild
//   caption -> textarea / Bildbeschreibung (optional)
//   aspect_ratio -> option / Seitenverhältnis Bild
//   image_size -> option / Bild Größe
//   buttons -> bloks
//   text_position -> option
// end of automatic

import React from "react"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"
import Figure from "gatsby-theme-yum-components/src/components/atoms/Figure"
import Headline from "gatsby-theme-yum-components/src/components/atoms/Headline"
import ButtonGroup from "gatsby-theme-yum-components/src/components/atoms/ButtonGroup"
import TextReveal from "gatsby-theme-yum-components/src/components/reveals/TextReveal"
import ImageReveal from "gatsby-theme-yum-components/src/components/reveals/ImageReveal"

const TextImageBlock = ({ block }) => {
  let imageWidth = ""
  let textWidth = ""

  if (block.image_size === "big") {
    imageWidth = "lg:w-2/3"
    textWidth = "lg:w-1/3"
  } else if (block.image_size === "small") {
    imageWidth = "lg:w-1/3"
    textWidth = "lg:w-2/3"
  } else if (block.image_size === "extra-small") {
    imageWidth = "lg:w-1/6"
    textWidth = "lg:w-5/6"
  } else {
    imageWidth = "lg:w-1/2"
    textWidth = "lg:w-1/2"
  }

  return (
    <BlockWrapper block={block} className="overflow-hidden ">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className={`relative w-full ${imageWidth} px-grid`}>
          <div
            className={`absolute -z-10 block w-24 h-24 ${
              block.text_position === "left"
                ? "left-0 transform -translate-x-10 -translate-y-6"
                : "right-0 transform translate-x-10 -translate-y-6"
            }`}
          >
            <ImageReveal>
              <div className="block w-24 h-24 opacity-75 bg-primary"></div>
            </ImageReveal>
          </div>

          {block.image?.filename && (
            <Figure image={block.image} caption={block.caption} />
          )}
        </div>
        <div
          className={`w-full mb-4 ${textWidth} px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <Headline
            kicker={block.kicker}
            headline={block.headline}
            headline_level={block.headline_level}
            subheadline={block.subheadline}
            className="pt-8 lg:pt-0"
          />
          <TextReveal>
            <Richtext text={block.text} className="pt-8" />
          </TextReveal>
          <div className="w-full mt-8 lg:w-1/2">
            <ButtonGroup className="lg:mt-1" buttons={block.buttons} />
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextImageBlock
