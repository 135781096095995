import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const useParallaxScroll = () => {
  const refItems = useRef([])
  const refParent = useRef(null)

  const refParallaxCollection = (el) => {
    if (el && !refItems.current.includes(el)) {
      refItems.current.push(el)
    }
  }

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: refParent.current,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
      },
    })

    refItems.current.forEach((layer) => {
      const depth = layer.dataset.depth
      // const movement = 500 * depth
      const movement = -(layer.offsetHeight * depth)
      tl.to(layer, { y: movement, ease: "none" }, 0)
    })

    // gsap.from(colRefs.current, {
    //   y: 200,
    //   duration: 6,
    //   stagger: 1,
    //   scrollTrigger: {
    //     markers: true,
    //     trigger: parentRef.current,
    //     endTrigger: endRef.current,
    //     scrub: true,
    //     start: "top bottom",
    //     end: "+=3000",
    //   },
    // })
  }, [])
  return { refParallaxCollection, refParent }
}

export default useParallaxScroll
