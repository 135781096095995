// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown
// end of automatic

import React from "react"
import Richtext from "../atoms/Richtext"
import TextReveal from "gatsby-theme-yum-components/src/components/reveals/TextReveal"
import BlockWrapper from "../technical/BlockWrapper"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      showHeadline="true"
      showButtons="true"
      blockDirection="horizontal"
      className=""
    >
      <TextReveal>
        <Richtext
          flow={block.flow}
          text={block.text}
          className={` ${block.text_size === "text-lg" ? block.text_size : ""}`}
        />
      </TextReveal>
    </BlockWrapper>
  )
}

export default TextBlock
