import React from "react"
import Slider from "react-slick"
import BlockWrapper from "./BlockWrapper"

const SliderWrapper = ({ children, showSlides }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
            <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
          </svg>
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
            <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
          </svg>
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: showSlides,
    slidesToScroll: showSlides,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="-mx-grid">
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default SliderWrapper
