import React, { useState } from "react"
import BlockWrapper from "../../technical/BlockWrapper"
import Blocks from "../../blocks/_Blocks"

const TabFolderBlock = ({ block }) => {
  const [activeTabState, setActiveTabState] = useState(1)
  return (
    <BlockWrapper block={block}>
      <nav className="mx-0.5">
        {block.folder_items &&
          block.folder_items.length > 0 &&
          block.folder_items.map((item, index) => (
            <button
              key={index}
              className={`inline-block py-2 font-bold border-2 border-gray-200 px-grid hover:bg-primary hover:border-primary ${
                activeTabState === index + 1 ? "bg-primary border-primary" : ""
              } -ml-0.5`}
              onClick={() => setActiveTabState(index + 1)}
              active
            >
              {item.title}
            </button>
          ))}
      </nav>
      <div className="relative w-full bg-gray-200">
        {block.folder_items &&
          block.folder_items.length > 0 &&
          block.folder_items.map((item, index) => (
            <div
              key={index}
              className={`${
                activeTabState === index + 1 ? "block" : "hidden"
              } `}
            >
              {item.content &&
                item.content.map((content) =>
                  React.createElement(Blocks(content.component), {
                    key: content._uid,
                    block: content,
                    type: content.component,
                  })
                )}
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default TabFolderBlock
